import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AdminPlaceholder = ({ children, className }) => (
  <div
    className={classNames(
      'items-center bg-gray-light7 text-gray flex flex-col text-[1.1rem] h-[250px] justify-center p-8 text-center w-full',
      className
    )}
  >
    {children}
  </div>
);

AdminPlaceholder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AdminPlaceholder;
