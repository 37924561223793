import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const JavaScriptEmbed = ({ js }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (!js) return;
    const jsTags = js.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gm).join();
    if (jsTags) {
      const slotHtml = document.createRange().createContextualFragment(js);
      divRef.current.innerHTML = '';
      divRef.current.appendChild(slotHtml);
    }
  }, [js]);

  return <div ref={divRef} />;
};

JavaScriptEmbed.propTypes = {
  js: PropTypes.string,
};

export default JavaScriptEmbed;
